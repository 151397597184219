import siteInfo from "@/constants/siteInfo";
import { Mukta } from "next/font/google";
const muktaFont = Mukta({
  subsets: ["latin"],
  weight: "400",
  display: "swap",
  variable: "--font-mukta",
});

export const ampGlobalStyles = `
html {
  font-family: ${muktaFont.style.fontFamily};
}

.text-primary {
  color: ${siteInfo.themeColor}
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

a {
  color: ${siteInfo?.themeColor};
}
.flex{
  display:flex;
}
.flex-center{
  display:flex;
  justify-content:center;
  align-items:center;
}
.flex-space{
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.flex-evenly{
  display:flex;
  justify-content:space-evenly;
  align-items:center;
}
.flex-col{
  display:flex;
  flex-direction:column;
}
.flex-col-center{
  align-items:center;
  margin-top: 2px;
}
.flex-row{
  display:flex;
  flex-direction:row;
}
.a-pointer{
  cursor: pointer;
  text-decoration: none;
  text-align:center;
}
.font-size16{
  font-size: 16px;
}
.font-size14{
  font-size: 14px;
}
.text-black{
  color: black;
  padding:0 1rem;
}
.footer-icon-amp{
  height: 1.25rem;
  width: 1.25rem;
  margin-bottom: 0.25rem;
}
.rounded{
  border-radius:50%;
}
.advertisement-text{
  font-size:12px;
  margin:0;
  margin-top:5px;
}
.advertisement-container{
  background-color: #eaeaea;
  min-height:200px;
}
.text-center{
  text-align:center;
}
.line-clam-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.breadcrumb {
  background: #eaeaea;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 0px;
  list-style: none;
}

.breadcrumb-item {
  font-size: 0.875rem;
  max-width: 15rem;
  overflow: hidden;
  
}

.breadcrumb-item:not(:last-child):after {
  content: " | ";
  margin: 0 4px;
  color: #afa8a8;
  font-weight: 500;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #333;
}

.rajText a {
  color: #FFA51A;
}
.MdText a {
  color: #0C8A70; 
}
.hrText a {
  color: #64BC46;
}.bhText a {
  color: #CF4C9B;
}
.share-logo-icons {
  height:40px;
  width:40px;
}
.text-center{
  text-align:center;
}
.footer-container{
  display:flex;
  justify-content:center;
  flex-direction:column;
  gap:20px;
  margin-top:50px;
}
.share-icons-container{
  display:flex;
  justify-content:center;
  flex-direction:row;
  gap:1rem;
}
.sidebar-item{
  margin:0;
  padding: 0.5rem 1rem;
}
.sidebar-item p{
  margin:0;
}
.amp-sidebar-logo{
  margin:0;
}
.sidebar-item a{
text-decoration:none;
}
amp-sidebar{
  max-width:100vw;
  background:white;
}
.my-2{
  margin-top:2rem;
  margin-bottom:2rem;
}
.searchBox{
  margin:0;
  padding: 0 1rem 0.5rem 1rem;
} 
#search {
  padding-left: 0.5rem;
}
#sticky-ad{
  padding-bottom:50px;
}
.menu-title {
  width: 92%;
  font-weight:500;
  font-size:1.125rem;
}

.menu-title:after {
  display: inline-block;
  content: "▾";
  padding: 0 0 0 1.5rem;
  color: #000;
  float: right;
}
.copyright-disclaimer{
  background:rgb(229 231 235);
  height:40px;
}
.faq-container a{
  color: rgb(75 85 99);
}
.mx-4{
  margin-left:1rem;
  margin-right:1rem;
}
.my-1{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}
.liveDetail {
  font-size: 16px;
  list-style: none;
  padding:0;
}
.liveDetail .text-align-justify {
  text-align: justify;
}
.liveDetail .embedcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.liveDetail h2 {
  font-size: 18px;
}
.liveDetail h3 {
  font-size: 16px;
}
.liveDetail p {
  padding-top: 4px;
  padding-bottom: 4px;
}
.liveDetail pre {
  font-size: 16px;
  font-family: "mukta";
  line-height: 30px;
}
.liveDetail iframe {
  padding-top: 4px;
  padding-bottom: 4px;
}
.liveDetail strong {
  color: #444;
  padding-top: 1px;
  padding-bottom: 1px;
}
.liveDetail blockquote {
  border-left: 5px solid ${siteInfo.themeColor};
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  background-color: #fdf0eb;
}
.liveDetail a {
  color: ${siteInfo.themeColor};
}
@media (min-width: 576px) {
  .liveDetail img {
    max-width: 500px;
    max-height: 500px;
 }
}
.liveDetail figure[class="table"] > table {
  width: inherit;
}
.liveDetail table {
  overflow-wrap: break-word;
  word-break: break-all;
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
}
.liveDetail th, .liveDetail td {
  text-align: left;
  padding: 8px;
}
.liveDetail thead th {
  background-color: #e0e0e0;
  color: black;
}
.liveDetail thead tr {
  background-color: #e0e0e0;
  color: black;
  border-bottom: 1px solid #cbcbcb;
}
.liveDetail tbody tr {
  border-bottom: 1px solid #cbcbcb;
}
.liveDetail th {
  border-right: 1px solid #cbcbcb;
}
.liveDetail td {
  border-right: 1px solid #cbcbcb;
}
.liveDetail blockquote:before {
  color: ${siteInfo.themeColor};
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.liveDetail blockquote p {
  display: inline;
  word-wrap: break-word;
}
.liveDetail .container {
  position: relative;
  padding: 0.4rem 0.5rem; /* Equivalent to px-4 py-2 in Tailwind */
  border: 1px solid #e2e8f0; /* Equivalent to border class */
}

.liveDetail .date {
  color: #687481; /* Equivalent to text-zinc-500 class */
  font-size: 1rem; /* Equivalent to text-base class */
  font-weight: light; /* Equivalent to font-light class */
}

.liveDetail .title {
  color: #000; /* Equivalent to text-black class */
  font-size: 1.125rem; /* Equivalent to text-lg class */
  font-weight: 600; /* Equivalent to font-semibold class */
  line-height: 1.75; /* Equivalent to leading-relaxed class */
}

.liveDetail .description {
  color: #000; /* Equivalent to text-black class */
  font-size: 1.125rem; /* Equivalent to text-lg class */
  font-weight: 300; /* Equivalent to font-light class */
  line-height: 25.2px; /* Equivalent to leading-[25.20px] class */
}

.liveDetail .dot {
  width: 4.4px;
  height: 3.67px;
  left: 85.42px;
  top: 10.31px;
  position: absolute;
  transform-origin: top left;
  transform: rotate(90deg);
  background-color: rgba(184, 194, 206, 0.5); /* Equivalent to bg-stone-300 bg-opacity-50 class */
  border-radius: 9999px; /* Equivalent to rounded-full class */
}

.liveDetail .line {
  width: 1px;
  height: 19px;
  left: 0.5px;
  top: 10px;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liveDetail .dotInsideLine {
  width: 0.625rem;
  height: 0.625rem;
  background-color: ${siteInfo.themeColor}; /* Equivalent to bg-primary class */
  border-radius: 9999px; /* Equivalent to rounded-full class */
}

.outerContainer{
    width: 100%;
    border-radius: 15px;
    border: 1.5px solid #E6E6E6;
    margin-top: 10px;
}

.TitleDiv{
    padding: 10px 10px 10px 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #ffedee;
    color: #EE3436;
    font-size: 26px;
    font-weight: 400;
    display: flex;
    align-items: 'center';
}

.Heading{
    display: flex;
    padding: 0.3rem 0rem;
    font-size: 20px;
    font-weight: 500;
    color: #2E2E2E;
    margin: 0;
}

.rightArrow{    
    color: #EE3436;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.SubHead{
    padding: 20px 10px 10px 10px;
}

.Content{
    font-size: 18px;
    font-weight: 500;
    color: #606060;
    margin: 0px 0px 0px 8px;
}
.hideArticle {
    max-height:200px;
    filter: blur(3px);
    overflow: hidden;
}
.showArticle {
    display: block;
}

.imgDescription h2{
  font-size: 18px;
  line-height: 1.2;
}

.imgDescription h1{
  font-size: 18px;
}
.imgDescription p{
  font-size: 14px;
}

`;
