/**
 * Sends an event to Google Analytics.
 *
 * @param {object} params - The event parameters.
 * @param {string} params.eventName - The name of the event.
 * @param {string} params.pageTitle - The title of the page where the event occurred.
 * @param {string} params.pageLocation - The full URL of the page where the event occurred.
 * @param {string} params.searchQuery - (Optional) The full URL of the page where the event occurred.
 */
function sendGAEvent({
  eventName = "",
  pageTitle = "",
  pageLocation = "",
  searchQuery = undefined,
}) {
  // Check if gtag is defined on the window object
  const GACode = process.env.NEXT_PUBLIC_GACODE ?? "";
  if (
    typeof window.gtag === "function" &&
    eventName &&
    pageTitle &&
    pageLocation
  ) {
    // console.log("GA event SENT", {
    //   eventName,
    //   page_title: pageTitle,
    //   page_location: pageLocation,
    //   sent_to: GACode,
    // });
    let obj = {
      page_title: pageTitle,
      page_location: pageLocation,
      send_to: GACode,
    };
    if (searchQuery) {
      obj.search_query = searchQuery;
    }
    window.gtag("event", eventName, obj);
  } else {
    console.warn("gtag is not defined. Unable to send event to GA.");
  }
}
export default sendGAEvent;
