import { useEffect } from "react";

const ComscoreScript = () => {
  useEffect(() => {
    if (typeof window === "undefined") return;

    if (!window._comscore) {
      window._comscore = window._comscore || [];
      window._comscore.push({
        c1: "2",
        c2: "8549097",
        cs_ucfr: "",
        options: {
          enableFirstPartyCookie: true,
          bypassUserConsentRequirementFor1PCookie: true,
        },
      });
    }

    const beaconScriptExists = document.getElementById("comscore-beacon");
    if (!beaconScriptExists) {
      const script = document.createElement("script");
      script.id = "comscore-beacon";
      script.src = "https://sb.scorecardresearch.com/cs/8549097/beacon.js";
      script.async = true;
      script.defer = true; // Ensures it doesn’t block execution
      document.body.appendChild(script);
    }

    // Cleanup function
    return () => {
      const beaconScript = document.getElementById("comscore-beacon");
      if (beaconScript) beaconScript.remove();
    };
  }, []);

  return null;
};

export default ComscoreScript;
