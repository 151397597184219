export default {
  siteName: "NewsTak",
  siteAuthor: "newstak",
  siteDomain: "newstak.in",
  siteLogo: "/assets/brand-logo-1-ns.png",
  siteLogo2: "/assets/brand-logo-2-ns.png",
  siteLanguage: "hi",
  themeColor: "#EE3436",
  themeColorLight: "#fdf0eb",
  googleSiteVerification: "YbnNBYk4Rq0Vw53gQEV1EzmjayWDpEtXSVvSRWftwCU",
  facebookAppId: "3793166270914121",
  twitterSiteId: "@newstakofficial",
  twitterCreatorId: "@newstakofficial",
  sitemapPath: "/rssfeeds/sitemap.xml",
  socialLinks: {
    twitter: "https://twitter.com/newstakofficial",
    instagram: "https://www.instagram.com/newstakofficial",
    youtube: "https://www.youtube.com/@NewsTak",
    facebook: "https://facebook.com/newstakofficial",
    googleNewsLink:
      "https://news.google.com/publications/CAAqBwgKMNaFpQww6P2zBA",
    whatsappChannel:
      "https://www.whatsapp.com/channel/0029VaA2TUD8PgsJfyJ4cL37",
  },
  sitemapPath: "/rssfeeds/sitemap.xml",
};
