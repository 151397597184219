import { useRouter } from "next/router";
import { useEffect } from "react";

const ChartbeatScript = () => {
  const router = useRouter();

  useEffect(() => {
    if (typeof window === "undefined") return;

    // Load Chartbeat asynchronously
    const loadChartbeat = () => {
      if (window._sf_async_config) return; // Prevent duplicate loads

      window._sf_async_config = {
        uid: 60355,
        domain: "newstak.in",
        useCanonical: true,
        sections: "politics",
        authors: "NEWSTAK",
      };

      const script = document.createElement("script");
      script.src = "https://static.chartbeat.com/js/chartbeat.js";
      script.async = true;
      script.defer = true; // Ensures it doesn’t block rendering
      document.body.appendChild(script);
    };

    // Load once on initial page load
    loadChartbeat();

    // Handle virtual page tracking on route change
    const handleRouteChange = () => {
      if (window.pSUPERFLY) {
        window.pSUPERFLY.virtualPage({
          sections: "politics",
          authors: "NEWSTAK",
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return null;
};

export default ChartbeatScript;
