// import useIsMobile from "@/hooks/useIsMobile";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useState } from "react";

const Header = dynamic(() => import("@/component/Header"));
const LeftSideBar = dynamic(() => import("@/component/Sidebar/LeftSideBar"));
const SubMenu = dynamic(() =>
  import("@/component/Sidebar/LeftSideBar/SubMenu")
);

const Layout = ({ children, ...props }) => {
  const router = useRouter();
  const isRajasthan = router?.asPath?.includes("/rajasthan");
  const isMadhya = router?.asPath?.includes("/madhya-pradesh");
  const isHR = router?.asPath?.includes("/haryana");
  const isBH = router?.asPath?.includes("/bihar");
  const isUP = router?.asPath?.includes("/uttar-pradesh");

  const [clickedMenuIndex, setClickedMenuIndex] = useState(null);
  // Check if the current route matches the pattern /shorts/:slug
  const disableLayout =
    router.pathname === "/short-videos/[slug]" ||
    router.pathname === "/[category]/short-video/[slug]" ||
    router.pathname === "/[category]/[subcategory]/short-video/[slug]" ||
    router.pathname === "/vs/header_newstak" ||
    router.pathname === "/vs/footer_newstak" ||
    router.pathname === "/vs/leftsidebar_newstak";
  // console.log("isShortsRoute", isShortsRoute);
  // const isMobileDevice = useIsMobile();
  return (
    <div className={`max-w-layout mx-auto`}>
      {!disableLayout && (
        <>
          <Header header_menu={props?.menuData?.header_menu} isRajasthan={isRajasthan} isMadhya={isMadhya} isHR={isHR} isBH={isBH} isUP={isUP}/>

          <div className="md:flex">
            <div className="md:w-[13%]">
              <LeftSideBar
                header_menu={props?.menuData?.header_menu}
                clickedMenuIndex={clickedMenuIndex}
                setClickedMenuIndex={setClickedMenuIndex}
                isRajasthan={isRajasthan} isMadhya={isMadhya} isHR={isHR} isBH={isBH} isUP={isUP}
              />
            </div>

            <div className="md:w-[87%] relative">
              <SubMenu
                clickedMenuIndex={clickedMenuIndex}
                header_menu={props?.menuData?.header_menu}
                setClickedMenuIndex={setClickedMenuIndex}
                isRajasthan={isRajasthan} isMadhya={isMadhya} isHR={isHR} isBH={isBH} isUP={isUP}
              />
              <main>{children}</main>
            </div>
          </div>
        </>
      )}

      {/* Always render children, conditionally render other layout components */}
      {disableLayout && <main>{children}</main>}
    </div>
  );
};

export default Layout;
